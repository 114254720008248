import * as React from 'react'
import * as styles from './accordionServices.module.scss'
import {
  Accordion,
  AccordionItem,
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import AccordionArrow from '../accordionArrow/accordionArrow';

export default function AccordionServices(props) {
  const [isAnyOpen, setIsAnyOpen] = React.useState(false)
  const [isArchitectOpen, setIsArchitectOpen] = React.useState(false)
  const [isInfraOpen, setIsInfraOpen] = React.useState(false)
  const [isImpOpen, setIsImpOpen] = React.useState(false)
  const [isReengOpen, setIsReenOpen] = React.useState(false)
  const [isTestOpen, setIsTestOpen] = React.useState(false)
  const [isDocOpen, setIsDocOpen] = React.useState(false)
  const [isSupOpen, setIsSupOpen] = React.useState(false)
  return (
    <Accordion
      onChange={() => setIsAnyOpen(!isAnyOpen)}
      allowZeroExpanded={true} className={styles.accordion}
      style={{ color: props.color }}>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton className={styles.button} style={isAnyOpen && !isArchitectOpen ? { display: "none" } : {}}>
            Software architecture and design
            <AccordionArrow />
            <AccordionItemState>
              {({ expanded }) => (expanded ? setIsArchitectOpen(true) : setIsArchitectOpen(false))}
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={styles.itemBody}>
          <p className={styles.itemParagraph}>
            This is a critical stage which sets up the rest of the project. Here, we perform requirements gathering by setting up meetings with stakeholders and asking the right questions.
          </p>
          <p className={styles.itemParagraph}>
            Next, we come up with a software architecture that suits your requirements whilst keeping the running costs to a minimum. We then present this solution to you and ask for your feedback. Once we are happy with the architecture we move onto the next stage.
          </p>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton className={styles.button} style={isAnyOpen && !isInfraOpen ? { display: "none" } : {}}>
            Infrastructure setup and management
            <AccordionArrow />
            <AccordionItemState>
              {({ expanded }) => (expanded ? setIsInfraOpen(true) : setIsInfraOpen(false))}
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={styles.itemBody}>
          <p className={styles.itemParagraph}>
            In this stage, we setup all the infrastructure to run your software. This includes things like CICD pipelines, Virtual Networks, Kubernetes Clusters and Security.
          </p>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton className={styles.button} style={isAnyOpen && !isImpOpen ? { display: "none" } : {}}>
            Implementation
            <AccordionArrow />
            <AccordionItemState>
              {({ expanded }) => (expanded ? setIsImpOpen(true) : setIsImpOpen(false))}
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={styles.itemBody}>
          <p className={styles.itemParagraph}>
            This is the fun part. This is where we build the software according to the requirements and architecture designs. During this stage we set up weekly meetings to get frequent feedback on our deliverables and to ensure everything is built to your satisfaction.
          </p>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton className={styles.button} style={isAnyOpen && !isReengOpen ? { display: "none" } : {}}>
            Reengineering and migration
            <AccordionArrow />
            <AccordionItemState>
              {({ expanded }) => (expanded ? setIsReenOpen(true) : setIsReenOpen(false))}
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={styles.itemBody}>
          <p className={styles.itemParagraph}>
            Do you have an existing project but it’s impossible to add new features, costs more than it should to run, or wish to move to a new tech stack? We have the skills provided to migrate you to the latest technologies on the Microsoft Azure platform and reengineer your system to make it scalable, extendable and maintainable.
          </p>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton className={styles.button} style={isAnyOpen && !isTestOpen ? { display: "none" } : {}}>
            Testing and quality assurance
            <AccordionArrow />
            <AccordionItemState>
              {({ expanded }) => (expanded ? setIsTestOpen(true) : setIsTestOpen(false))}
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={styles.itemBody}>
          <p className={styles.itemParagraph}>
            During the Implementation stage we ensure all software is surround surrounded by tests.
            We adhere to the testing pyramid where the majority of our tests are Unit Tests, followed by Integration Tests to test component interaction, and Functional Tests for deployed instances.
            Tests are run as part of the build and release pipeline to ensure all software is functioning properly.
          </p>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton className={styles.button} style={isAnyOpen && !isDocOpen ? { display: "none" } : {}}>
            Technical documentation
            <AccordionArrow />
            <AccordionItemState>
              {({ expanded }) => (expanded ? setIsDocOpen(true) : setIsDocOpen(false))}
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={styles.itemBody}>
          <p className={styles.itemParagraph}>
            Maintaining someone else’s code can be hard. While we strive to write elegant and self documenting code, sometimes you still need documentation. We are happy to provide documentation for any of the software we build.
          </p>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton className={styles.button} style={isAnyOpen && !isSupOpen ? { display: "none" } : {}}>
            Maintenance and support
            <AccordionArrow />
            <AccordionItemState>
              {({ expanded }) => (expanded ? setIsSupOpen(true) : setIsSupOpen(false))}
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={styles.itemBody}>
          <p className={styles.itemParagraph}>
            While we like to think our software is built to perfection, sometimes things can happen outside of our control. In this instance we can provide support and maintenance for times when things do go wrong.
          </p>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

