import * as React from 'react'
import * as styles from './aboutUsSection.module.scss'
import SectionTitle from '../sectionTitle/sectionTitle'
import SectionMain from '../sectionMain/sectionMain'
import { StaticImage } from 'gatsby-plugin-image';
import AccordionSingle from '../accordionSingle/accordionSingle';
import SectionMainWhite from '../sectionMainWhite/sectionMainWhite';


export default function AboutUsSection() {
  const [whyBgActive, setWhyBgActive] = React.useState(false);
  const [whatBgActive, setWhatBgActive] = React.useState(false);

  function changeWhyBackground() {
    setWhyBgActive(!whyBgActive);
  }

  function changeWhatBackground() {
    setWhatBgActive(!whatBgActive);
  }

  return (
    <div id="about-us" className={styles.container}>
      <SectionTitle text="About us" />
      <div className={styles.innerContainer}>
        <div className={styles.containerLeft}>
          <SectionMain>
            <p>
              We build bespoke software solutions for businesses looking to become more efficient, go digital, or expand into the cloud.
            </p>
            <AccordionSingle color="#000000">
              <p>

                Our software is designed and built to the highest standards and made to be resilient, maintainable, and fast.
                We are a business which aims to amplify our clients’ goals, quality of service and how to better utilise their functionality.
                We offer ways in which our client’s services and products can be more automated and efficient.
              </p>
              <p>
                Some technology we use:
              </p>
              <p>
                Microsoft Azure, C#, .Net, Javacript, React, Python, Kubernetes, Docker, Shopify, SharePoint.
              </p>
            </AccordionSingle>
          </SectionMain>
        </div>
        <div className={styles.containerRight}>
          <div className={styles.pictureSection} style={whyBgActive ? { backgroundColor: "#64646E" } : {}}>
            <SectionMainWhite>Why work with us</SectionMainWhite>
            <div className={styles.imgOverlay}></div>
            <div className={styles.imgOverlay2}></div>
            <AccordionSingle onChange={() => changeWhyBackground()} color="#ffffff">
              <p className={styles.pictureSectionText}>
                We are a small team that brings a compatible set of software architectural and engineering skills to the table. Our size, work ethic and approach to each client makes us unique in being able to deliver very specific and tailored solutions.
              </p>
              <p className={styles.pictureSectionText}>
                Our ethos around client engagement has proven to be successful in ensuring we are personable throughout the production and delivery phases. We strive to understand what you want by asking the right questions up front and continue to validate our progress with weekly meetings to demonstrate progress and receive any feedback.
              </p>
              <p className={styles.pictureSectionText}>
                We are passionate about technology and keep up to date with the latest technology, however, we always use the right tool for the job to ensure our software is resilient, maintainable, and fast.
              </p>
            </AccordionSingle>
            <StaticImage
              src="../../images/exify-unsplash-1.jpg"
              alt="why hire us"
              className={styles.pictureSectionImage}
              objectFit="cover"
              objectPosition="54% 100%"
            />
          </div>
          <div className={styles.pictureSection} style={whatBgActive ? { backgroundColor: "#64646E" } : {}}>
            <SectionMainWhite>What drives us</SectionMainWhite>
            <div className={styles.imgOverlay}></div>
            <div className={styles.imgOverlay2}></div>
            <AccordionSingle onChange={() => changeWhatBackground()} color="#ffffff">
              <p className={styles.pictureSectionText}>
                We have a drive to help businesses function at a higher level by building elegant software. Our mission is to uplift and ensure the clients we work with are functioning at their best capabilities and are working in the most efficient way in which they deliver their business or products.
              </p>
            </AccordionSingle>
            <StaticImage
              src="../../images/exify-unsplash-10.jpg"
              alt="why hire us"
              className={styles.pictureSectionImage}
              objectFit="cover"
              objectPosition="25% 0%"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

