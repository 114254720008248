import * as React from 'react'
import * as styles from './contactSection.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { StaticImage } from 'gatsby-plugin-image'
import SectionTitle from '../sectionTitle/sectionTitle'
import SectionMain from '../sectionMain/sectionMain'
import ReactGA from 'react-ga';
import MailTo from '../mailTo/mailTo'

const linkedInLink = "https://www.linkedin.com/company/exifylimited"

export default function ContactSection() {
  function LinkedInClick() {
    ReactGA.event({
      category: 'LinkedIn',
      action: 'Click'
    })
  }

  return (
    <div id="contact" className={styles.container}>
      <SectionTitle text="Contact" />
      <div className={styles.innerContainer}>
        <div className={styles.containerLeft}>
          <SectionMain>
            <p>
              If you have a project in mind or a general enquiry,
              please get in touch with Tom Brereton at <MailTo>enquire@exify.net</MailTo>
            </p>
          </SectionMain>
          <div className={styles.textBottom}>
            <div className={styles.social}>
              <p>Follow us</p>
              <a href={linkedInLink} onClick={LinkedInClick}>
                <FontAwesomeIcon size="4x" icon={faLinkedin} />
              </a>
            </div>
            <div className={styles.office}>
              <p className={styles.officeTitle}>Remote working</p>
              <p>We are a remote team and available to work world wide, in any timezone. We are also available to travel and meet face to face in the UK and Europe.</p>
              <p>For virtual meetings we are happy to use any platform.</p>
            </div>
          </div>
        </div>
        <div className={styles.contactRight}>
          <StaticImage
            src="../../images/exify-unsplash-9.jpg"
            alt="Contact"
            className={styles.imageRight}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/triangle-green2.svg"
            alt="Branding green triangle"
            className={styles.brandingTriangle}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      </div >
    </div >
  )
}

