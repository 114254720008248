import * as React from 'react'
import * as styles from './accordionArrow.module.scss'
import {
  AccordionItemState,
} from 'react-accessible-accordion';
import { StaticImage } from 'gatsby-plugin-image';


export default function AccordionArrow(props) {
  return (
    <AccordionItemState>
      {({ expanded }) => {
        if (!expanded) {
          return (
            <StaticImage
              src="../../images/down-arrow-white.svg"
              alt='down arrow'
              className={styles.downArrow}
              loading="eager"
              placeholder="blurred"
            />
          )
        }
        if (expanded) {
          return (
            <StaticImage
              src="../../images/up-arrow-white.svg"
              alt='up arrow'
              className={styles.downArrow}
              loading="eager"
              placeholder="blurred"
            />
          )
        }
      }}
    </AccordionItemState>
  )
}

