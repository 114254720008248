import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './landingSection.module.scss'

export default function LandingSection() {
  return (
    <div id="home" className={styles.landingSection}>
      <StaticImage
        src="../../images/exify-unsplash-13.jpg"
        alt="A person working at their laptop"
        className={styles.landingImage}
        objectFit="cover"
        objectPosition="26% 0%"
      />
      <div className={styles.horizontalLine}></div>
      <div className={styles.landingText}>
        <StaticImage
          src="../../images/triangle-green.svg"
          alt="Branding green triangle"
          className={styles.brandingTriangleMobile}
        />
        <StaticImage
          src="../../images/triangle-green3.svg"
          alt="Branding green triangle"
          className={styles.brandingTriangleDesktop}
        />
        <h1 className={styles.title}>Expand Your Business</h1>
        <div className={styles.landingTextRightBox}></div>
      </div>
      <div className={styles.bottomBox}>
        <div className={styles.leftBottomBox}></div>
        <div className={styles.rightBottomBox}>
          <a href="#about-us">
            <StaticImage
              src="../../images/down-arrow-green.svg"
              alt="Down Arrow"
              className={styles.downArrow}
              loading="eager"
              placeholder="blurred"
            />
          </a>
        </div>
      </div>
    </div>
  )
}
