// extracted by mini-css-extract-plugin
export var landingSection = "landingSection-module--landingSection--2gmh0";
export var landingImage = "landingSection-module--landingImage--aeCie";
export var horizontalLine = "landingSection-module--horizontalLine--DaZ-5";
export var landingText = "landingSection-module--landingText--BSf7c";
export var brandingTriangleMobile = "landingSection-module--brandingTriangleMobile--W4FNW";
export var brandingTriangleDesktop = "landingSection-module--brandingTriangleDesktop--7ZhI+";
export var title = "landingSection-module--title--q1o6m";
export var landingTextRightBox = "landingSection-module--landingTextRightBox--RhcVT";
export var bottomBox = "landingSection-module--bottomBox--o2+Zp";
export var leftBottomBox = "landingSection-module--leftBottomBox--mt8yw";
export var rightBottomBox = "landingSection-module--rightBottomBox--JEPNX";
export var downArrow = "landingSection-module--downArrow--wUoMF";