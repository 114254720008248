import * as React from 'react'
import * as styles from './header.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import BrandButton from '../brandButton/brandButton'
import BurgerMenu from '../burgerMenu/burgerMenu'
import MailTo from '../mailTo/mailTo'

export default function Header() {
  return (
    <header className={styles.header}>
      <StaticImage
        id="first-logo"
        alt="Logo"
        src="../../images/Exify_Logo_White_RGB.svg"
        width={94}
        className={styles.logo}
      />
      <div className={styles.mobileOnly}>
        <BurgerMenu />
      </div>
      <div className={styles.desktopOnly}>
        <a className={styles.link} href="#about-us">About Us</a>
        <a className={styles.link} href="#services">Our Services</a>
        <a className={styles.link} href="#contact">Contact Us</a>

        <BrandButton>
          <MailTo>
            Enquire
          </MailTo>
        </BrandButton>
      </div>
    </header>
  )
}

