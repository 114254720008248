import '../css/typography.scss'
import * as React from 'react'
import '../css/global.scss'
import Layout from '../components/layout/layout';
import LandingSection from '../components/landingSection/landingSection';
import ContactSection from '../components/contactSection/contactSection';
import Footer from '../components/footer/footer';
import AboutUsSection from '../components/aboutUsSection/aboutUsSection';
import ServicesSection from '../components/servicesSection/servicesSection';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-213510944-1');

const IndexPage = () => {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Exify | IT Consultancy</title>
        <link rel="canonical" href="https://www.exify.net" />
        <meta name="description" content="We build bespoke software solutions for businesses looking to become more efficient, go digital, or expand into the cloud. Experts in Microsoft Azure, C#, .Net, Javacript, React, Python, Shopify, SharePoint, Kubernetes, and Docker." />
      </Helmet>
      <LandingSection />
      <AboutUsSection />
      <ServicesSection />
      <ContactSection />
      <Footer />
    </Layout>
  )
}

export default IndexPage
