import * as React from 'react'
import Header from '../header/header'
import * as styles from './layout.module.scss'

export default function Layout({ children }) {
  if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <div className={styles.layout}>
      <Header />
      <main className={styles.main}>{children}</main>
    </div>
  )
}

