// extracted by mini-css-extract-plugin
export var container = "servicesSection-module--container--SV-TY";
export var innerContainer = "servicesSection-module--innerContainer--Zw3dF";
export var containerLeft = "servicesSection-module--containerLeft--gjWB9";
export var containerRight = "servicesSection-module--containerRight--AZBLy";
export var servicesSectionText = "servicesSection-module--servicesSectionText--sM5qF";
export var pictureSectionImage = "servicesSection-module--pictureSectionImage--qxMFn";
export var imgOverlay = "servicesSection-module--imgOverlay--bOuUd";
export var imgOverlay2 = "servicesSection-module--imgOverlay2--iG4FX";
export var bottomBox = "servicesSection-module--bottomBox--kuxXy";
export var leftBottomBox = "servicesSection-module--leftBottomBox--4EL0A";
export var rightBottomBox = "servicesSection-module--rightBottomBox--Wumbc";