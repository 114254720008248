// extracted by mini-css-extract-plugin
export var container = "aboutUsSection-module--container--zJov4";
export var innerContainer = "aboutUsSection-module--innerContainer--tMj-K";
export var containerLeft = "aboutUsSection-module--containerLeft--xw4eO";
export var containerRight = "aboutUsSection-module--containerRight--Zr-SJ";
export var techStack = "aboutUsSection-module--techStack--glp8I";
export var techLogos = "aboutUsSection-module--techLogos--tj41C";
export var techLogo = "aboutUsSection-module--techLogo--VUrpB";
export var pictureSectionImage = "aboutUsSection-module--pictureSectionImage--OWbeg";
export var pictureSection = "aboutUsSection-module--pictureSection--RzwhR";
export var pictureSectionText = "aboutUsSection-module--pictureSectionText--Qnhx-";
export var imgOverlay = "aboutUsSection-module--imgOverlay--p-teC";
export var imgOverlay2 = "aboutUsSection-module--imgOverlay2--L1Ks8";