// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--lU7l1";
export var topBox = "footer-module--topBox--TRcUU";
export var leftSection = "footer-module--leftSection--aOrWu";
export var rightSection = "footer-module--rightSection--r2ZME";
export var exifyLogo = "footer-module--exifyLogo--FQcu1";
export var links = "footer-module--links--83gnD";
export var link = "footer-module--link--svGkA";
export var bottomBox = "footer-module--bottomBox--Ft9A9";
export var leftBottomBox = "footer-module--leftBottomBox--5-D5E";
export var rightBottomBox = "footer-module--rightBottomBox--TiMtZ";