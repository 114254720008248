import * as React from 'react'
import * as styles from './sectionTitleWhite.module.scss'

export default function SectionTitleWhite(props) {
  return (
    <div className={styles.title}>
      <p className={styles.titleText}>{props.text}</p>
    </div>
  )
}

