// extracted by mini-css-extract-plugin
export var contact = "contactSection-module--contact--GA3Ir";
export var container = "contactSection-module--container--Y9+zl";
export var innerContainer = "contactSection-module--innerContainer--eZs2i";
export var containerLeft = "contactSection-module--containerLeft--ZUohX";
export var containerRight = "contactSection-module--containerRight--xXoFO";
export var contactRight = "contactSection-module--contactRight--9EQfh";
export var imageRight = "contactSection-module--imageRight--xsvVT";
export var brandingTriangle = "contactSection-module--brandingTriangle--xAGSE";
export var contactLeft = "contactSection-module--contactLeft--W0XE6";
export var textBottom = "contactSection-module--textBottom--fBWKr";
export var social = "contactSection-module--social--xBt6R";
export var office = "contactSection-module--office--dzg9Y";
export var officeTitle = "contactSection-module--officeTitle--fyLUe";