import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import * as styles from "./footer.module.scss";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.topBox}>
        <div className={styles.leftSection}>
          <div className={styles.exifyLogo}>
            <StaticImage
              alt="Logo 2"
              src="../../images/Exify_Logo_White_RGB.svg"
              width={94}
            />
          </div>
          <div className={styles.links}>
            <a className={styles.link} href="#home">
              Home
            </a>
            <a className={styles.link} href="#about-us">
              About Us
            </a>
            <a className={styles.link} href="#services">
              Our Services
            </a>
            <a className={styles.link} href="#contact">
              Contact Us
            </a>
          </div>
        </div>
        <div className={styles.rightSection}>
          <p>
            Exify build bespoke software solutions for businesses looking to
            become more efficient, go digital, or expand into the cloud.
          </p>
        </div>
      </div>
      <div className={styles.bottomBox}>
        <div className={styles.leftBottomBox}>
          <p>Exify ©2021</p>
        </div>
        <div className={styles.rightBottomBox}>
          {/* <p>Website design by <a href="https://www.jonathan-martin.co.uk">JM&Co</a></p> */}
          <p>
            <a href="https://artiodevadb2c.b2clogin.com/artiodevadb2c.onmicrosoft.com/b2c_1a_signup_signin/oauth2/v2.0/authorize?client_id=e699ef64-96d2-4ac7-9497-6ba7b3536ebf&redirect_uri=https%3A%2F%2Fportal-dev.onceforall.com%2Fhome%2Flogin_complete&state=sso%3dtrue&response_type=token%20id_token&scope=https%3A%2F%2Fartiodevadb2c.onmicrosoft.com%2Fartiobackend%2Fuser_impersonation%20profile%20openid&response_mode=fragment&domain_hint=Exify">
              Login to Artio
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
