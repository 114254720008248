import * as React from 'react'
import * as styles from './accordionSingle.module.scss'
import {
  Accordion,
  AccordionItem,
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { StaticImage } from 'gatsby-plugin-image';


export default function AccordionSingle(props) {
  let isWhite = props.color === 'white' || props.color.toLowerCase() === '#ffffff';
  return (
    <Accordion
      onChange={typeof props.onChange === 'function' ? () => props.onChange() : () => ''}
      allowZeroExpanded={true} className={styles.accordion}
      style={{ color: props.color }}>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton className={styles.button}>
            <AccordionItemState>
              {({ expanded }) => (expanded ? 'CLOSE' : 'SEE MORE')}
            </AccordionItemState>
            <AccordionItemState>
              {({ expanded }) => {
                if (!expanded && !isWhite) {
                  return (
                    <StaticImage
                      src="../../images/down-arrow-black.svg"
                      alt='down arrow'
                      className={styles.downArrow}
                      loading="eager"
                      placeholder="blurred"
                    />
                  )
                }
                if (!expanded && isWhite) {
                  return (
                    <StaticImage
                      src="../../images/down-arrow-white.svg"
                      alt='down arrow'
                      className={styles.downArrow}
                      loading="eager"
                      placeholder="blurred"
                    />
                  )
                }
                if (expanded && !isWhite) {
                  return (
                    <StaticImage
                      src="../../images/up-arrow-black.svg"
                      alt='up arrow'
                      className={styles.downArrow}
                      loading="eager"
                      placeholder="blurred"
                    />
                  )
                }
                if (expanded && isWhite) {
                  return (
                    <StaticImage
                      src="../../images/up-arrow-white.svg"
                      alt='up arrow'
                      className={styles.downArrow}
                      loading="eager"
                      placeholder="blurred"
                    />
                  )
                }
              }}
            </AccordionItemState>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={styles.itemBody}>
          {props.children}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

