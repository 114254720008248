import * as React from 'react'
import * as styles from './sectionMain.module.scss'

export default function SectionMain(props) {
  return (
    <div className={styles.text}>
      {props.children}
    </div>
  )
}

