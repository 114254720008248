
import * as React from 'react'
import ReactGA from 'react-ga';

const mailToLink = "mailto:enquire@exify.net?subject=Project Enquiry&body=Hi Tom"


export default function MailTo({ children }) {

	function MailToClick() {
		ReactGA.event({
			category: 'Email',
			action: 'Click'
		})
	}

	return (
		<a href={mailToLink} onClick={MailToClick}>{children}</a>
	)
}

