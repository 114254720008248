import * as React from 'react'
import * as styles from './servicesSection.module.scss'
import { StaticImage } from 'gatsby-plugin-image';
import BrandTitle from '../brandTitle/brandTitle';
import SectionTitleWhite from '../sectionTitleWhite/sectionTitleWhite';
import AccordionServices from '../accordionServices/accordionServices';


export default function ServicesSection() {
  return (
    <div id="services" className={styles.container}>
      <SectionTitleWhite text="Our services" />
      <div className={styles.innerContainer}>
        <div className={styles.containerLeft}>
          <BrandTitle text="Excel By Design" />
          <StaticImage
            src="../../images/exify-unsplash-8.jpg"
            alt="why hire us"
            className={styles.pictureSectionImage}
            objectFit="cover"
            objectPosition="54% 100%"
          />
          <div className={styles.imgOverlay}></div>
          <div className={styles.imgOverlay2}></div>
        </div>
        <div className={styles.containerRight}>
          <p className={styles.servicesSectionText}>
            Exify covers the entire product life-cycle for any software development services. Allow us to handle the entire life-cycle or select what you need from the list below.
          </p>
          <AccordionServices />
        </div>
      </div>
      <div className={styles.bottomBox}>
        <div className={styles.leftBottomBox}></div>
        <div className={styles.rightBottomBox}></div>
      </div>
    </div>
  )
}

