import React, { useState, useCallback, useEffect } from "react";
// import { HamburgerSpring } from 'react-animated-burgers'
import * as styles from "./burgerMenu.module.scss";
import { StaticImage } from "gatsby-plugin-image";

export default function BurgerMenu() {
  function openNav() {
    if (typeof window !== "undefined") {
      document.getElementById("mySidenav").style.width = "100%";

      document.getElementsByClassName(`${styles.logo}`)[0].style.visibility =
        "visible";
      document.getElementsByClassName(`${styles.logo}`)[0].style.opacity = "1";

      document.getElementsByTagName("body")[0].style.overflow = "hidden";

      let links = document.getElementsByName("menu-link");
      links.forEach(function (item) {
        item.style.visibility = "visible";
        item.style.opacity = "1";
      });
    }
  }

  function closeNav() {
    if (typeof window !== "undefined") {
      document.getElementById("mySidenav").style.width = "0";

      document.getElementsByClassName(`${styles.logo}`)[0].style.visibility =
        "hidden";
      document.getElementsByClassName(`${styles.logo}`)[0].style.opacity = "0";

      document.getElementsByTagName("body")[0].style.overflow = "auto";

      let links = document.getElementsByName("menu-link");
      links.forEach(function (item) {
        item.style.visibility = "hidden";
        item.style.opacity = "0";
      });
    }
  }

  const [isActive, setIsActive] = useState(false);

  const toggleButton = useCallback(
    () => setIsActive((prevState) => !prevState),
    []
  );

  useEffect(() => {
    if (isActive) {
      openNav();
    }
    if (!isActive) {
      closeNav();
    }
  });

  return (
    <>
      <a name="menu-link" onClick={() => toggleButton()} href="#home">
        <StaticImage
          id="logo"
          alt="Logo"
          src="../../images/Exify_Logo_White_RGB.svg"
          width={94}
          className={styles.logo}
        />
      </a>

      <div id="mySidenav" className={styles.sidenav}>
        <a
          name="menu-link"
          className={styles.link}
          onClick={() => toggleButton()}
          href="#home"
        >
          Home
          <StaticImage
            alt="right arrow"
            src="../../images/right-arrow-green.png"
            width={25}
            className={styles.arrow}
          />
        </a>
        <a
          name="menu-link"
          className={styles.link}
          onClick={() => toggleButton()}
          href="#about-us"
        >
          About Us
          <StaticImage
            alt="right arrow"
            src="../../images/right-arrow-green.png"
            width={25}
            className={styles.arrow}
          />
        </a>
        <a
          name="menu-link"
          className={styles.link}
          onClick={() => toggleButton()}
          href="#services"
        >
          Our Services
          <StaticImage
            alt="right arrow"
            src="../../images/right-arrow-green.png"
            width={25}
            className={styles.arrow}
          />
        </a>
        <a
          name="menu-link"
          className={styles.link}
          onClick={() => toggleButton()}
          href="#contact"
        >
          Contact Us
          <StaticImage
            alt="right arrow"
            src="../../images/right-arrow-green.png"
            width={25}
            className={styles.arrow}
          />
        </a>

        <div className={styles.bottomBox}>
          <div className={styles.leftBottomBox}></div>
          <div className={styles.rightBottomBox}></div>
        </div>
      </div>

      {/* <HamburgerSpring
        className={styles.burgerButton}
        barColor="#03fcbe"
        {...{ isActive, toggleButton }}
      /> */}
    </>
  );
}
